<template>
    <div class="hero min-h-screen bg-base-200" data-theme="dark">
        <div v-if="status == 'login'" class="hero-content flex-col lg:flex-row-reverse">
            <div class="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                <form class="card-body">
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">Username</span>
                        </label>
                        <input disabled type="text" placeholder="username" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\w]/g, '')" v-model="user.username" />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">Password</span>
                        </label>
                        <input disabled type="password" placeholder="password" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\w]/g, '')" v-model="user.password" />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">app_id</span>
                        </label>
                        <input type="text" placeholder="app_id" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\w]/g, '')" v-model="user.app_id" />
                    </div>
                    <div v-if="isLoading">
                        <div class="form-control mt-6 flex justify-center items-center">
                            <span class="loading loading-ring loading-lg"></span>

                        </div>
                    </div>
                    <div v-else>
                        <div class="form-control mt-6">
                            <button class="btn btn-primary" v-on:click.prevent="submitLogin">Login</button>
                        </div>
                        <div class="form-control mt-6">
                            <button type="button" class="btn btn-neutral"
                                v-on:click="changeStatus('register')">Register</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <div v-else-if="status == 'register'" class="hero-content flex-col lg:flex-row-reverse">
            <div class="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                <form class="card-body">
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">Display Name</span>
                        </label>
                        <input type="text" placeholder="display name" class="input input-bordered" required maxlength="20"
                            v-model="user.displayName" />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">Username</span>
                        </label>
                        <input disabled type="text" placeholder="username" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\w]/g, '')" v-model="user.username" />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">Password</span>
                        </label>
                        <input disabled type="text" placeholder="password" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\w]/g, '')" v-model="user.password" />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">App_ID</span>
                        </label>
                        <input type="text" placeholder="app_id" class="input input-bordered" required maxlength="20"
                            oninput="this.value = this.value.replace(/[^\d]/g, '')" v-model="user.app_id" />
                    </div>
                    <div v-if="isLoading">
                        <div class="form-control mt-6">
                            <progress class="progress w-56"></progress>
                        </div>
                    </div>
                    <div v-else>
                        <br>
                        <div class="join grid grid-cols-2">
                            <button type="button" class="join-item btn btn-outline"
                                v-on:click=" changeStatus('login')">Back</button>
                            <button class="join-item btn btn-outline btn-accent"
                                v-on:click.prevent="submitRegister">Register</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else-if="status == 'logged'" class="hero-content flex-col lg:flex-row-reverse">
            <div class="card card-side bg-base-100 shadow-xl">
                <div class="card-body">
                    <label class="form-control">
                        <div class="label">
                            <span class="label-text">displayName</span>
                        </div>
                        <input readonly type="text" placeholder="You can't touch this" v-model="user.displayName"
                            class="input input-bordered w-full max-w-xs" />
                    </label>
                    <label class="form-control">
                        <div class="label">
                            <span class="label-text">app_id</span>
                        </div>
                        <input readonly type="text" placeholder="You can't touch this" v-model="user.app_id"
                            class="input input-bordered w-full max-w-xs" />
                    </label>
                    <label class="form-control">
                        <div class="label">
                            <span class="label-text">Token</span>
                        </div>
                        <textarea readonly v-model="token"
                            class="textarea textarea-bordered textarea-xs w-full max-w-xs"></textarea>
                    </label>
                    <h2 class="card-title"></h2>
                    <p></p>
                    <div class="card-actions justify-end">
                        <!-- <button class="btn btn-accent" v-on:click="clickIframe">open modal</button> -->
                        <button class="btn btn-primary" v-on:click="changeStatus('login')">Logout</button>
                    </div>
                </div>
                <div>
                    <!-- <MiniIFrame ref="myIframe" @updateDomainIframe="updateDomainIframe"></MiniIFrame> -->
                    <dialog id="my_modal_2" class="modal">
                        <div class="modal-box">
                            <div v-if="isLoading">
                                <progress class="progress w-56"></progress>
                            </div>
                            <div v-else>
                                <div class="flex w-full">
                                    <div class="container">
                                        <div class="avatar-container">
                                            <div class="avatar">
                                                <div class="w-24 rounded-full">
                                                    <img :src="modalData.app_image" />
                                                </div>
                                            </div>
                                            <p class="app_and_room_name">{{ modalData.app_name }}</p>
                                            <label class="app_and_room_id">APP_ID : {{ modalData.app_id }}</label>
                                            <label class="user_label">USER_ID : {{ modalData.user_id }}</label>
                                        </div>
                                    </div>

                                    <div class="divider divider-horizontal"></div>

                                    <div class="container">
                                        <div class="avatar-container">
                                            <div class="avatar">
                                                <div class="w-24 rounded-full">
                                                    <img :src="modalData.room_image" />
                                                </div>
                                            </div>
                                            <p class="app_and_room_name">{{ modalData.room_name }}</p>
                                            <label class="app_and_room_id">room_id : {{ modalData.room_id }}</label>
                                            <label class="user_label">USER_TYPE : {{ modalData.user_type }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form method="dialog" class="modal-backdrop">
                            <button>close</button>
                        </form>
                    </dialog>
                </div>

            </div>

        </div>
    </div>
</template>
  
<script>

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import http from '../lib/axios'
// import MiniIFrame from '../lib/MiniIFrame.vue';



export default {
    name: 'app',
    data() {
        return {
            domainIframe: "",
            status: "login", // login , register , logged
            isLoading: false,
            user: {
                displayName: undefined,
                username: 'A',
                password: '1',
                app_id: 11
            },
            token: undefined,
            modalData: {
                app_id: undefined,
                app_name: undefined,
                app_image: undefined,
                room_id: undefined,
                room_name: undefined,
                room_image: undefined,
                user_id: undefined,
                user_type: undefined
            }
        }
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage, false); 
        console.log("https://widget.supth.com/widgetChat.js")
        const script = document.createElement("script")
        script.src = "https://widget.supth.com/widgetChat.js",
        script.async = true,
        script.onload = () =>{
            // window.widgetChat.default.initializeChatWidget("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkEiLCJhcHBfaWQiOjExLCJ0eXBlIjoiZ3Vlc3QiLCJ0b2tlbl9leHBpcmUiOjE3MDk4ODUyMDY4MzMsImlhdCI6MTcwOTg4NDkwNiwiZXhwIjoxNzA5ODg1MjA2fQ.Kwcvw9l_g0lrxVqlfUe5Tj3xm9J-RGqvbmsgTsMeL-U")
        }
        document.body.appendChild(script)

    },
    components: {
        // MiniIFrame
    },
    
    methods: {
        updateDomainIframe(domain) {
            this.domainIframe = domain;
            console.log('%c⧭', 'color: #aa00ff', this.domainIframe)
        },
        clickIframe() {
            this.isLoading = true
            
            this.$refs.myIframe.SendMessage({ token: this.token, url: this.domainIframe });
        },
        receiveMessage(event) {
            const myDomain = window.location.hostname + ":" + window.location.port
            if (event.origin.indexOf(myDomain) > 0) {
                return
            }
            const response = event.data
            if (response.return_code === "200") {
                this.modalData = response
                this.isLoading = false
                const myModal = document.getElementById('my_modal_2');
                myModal.showModal()
            } else {
                this.isLoading = false
                // console.log('%c⧭', 'color: #00bf00', response)
                // console.log('%c⧭', 'color: #917399', event)
                // this.notification(response.message || response.return_message)
            }
        },
        async submitRegister() {
            if (!this.isFormValid()) return
            const json = {
                username: this.user.username,
                password: this.user.password,
                app_id: parseInt(this.user.app_id),
                displayname: this.user.displayName
            }
            try {
                this.isLoading = true
                const response = await http.post('/users/register', json)
                if (response.data.success) {
                    const result = response.data.data
                    this.user.app_id = result.app_id
                    this.user.displayName = result.displayname
                    this.token = result.token
                    this.status = 'logged'
                    window.widgetChat.default.initializeChatWidget(this.token)

                    this.isLoading = false
                } else {
                    this.isLoading = false
                    this.notification(response.data.message)
                }
            } catch (error) {
                this.isLoading = false
                console.error('Error during login:', error);
                this.notification('An error occurred during login.');
            }
        },
        async submitLogin() {
            if (!this.isFormValid()) return
            try {
                const json = {
                    username: this.user.username,
                    password: this.user.password,
                    app_id: parseInt(this.user.app_id)
                }
                this.isLoading = true
                const response = await http.post('/users/login', json)
                if (response.data.success) {
                    const result = response.data.data
                    this.user.app_id = result.app_id
                    this.user.displayName = result.displayname
                    this.token = result.token
                    this.status = 'logged'
                    this.isLoading = false
                    window.widgetChat.default.initializeChatWidget(this.token)
                } else {
                    this.isLoading = false
                    this.notification(response.data.message)
                }
            } catch (error) {
                this.isLoading = false
                console.error('Error during login:', error);
                this.notification('An error occurred during login.');
            }
        },
        isFormValid() {
            if (this.status == "login") {
                return (this.user.username && this.user.password)
            } else {
                return (this.user.username && this.user.password && this.user.app_id && this.user.displayName)
            }
        },
        changeStatus(status) {
            this.user = {
                displayName: undefined,
                username: 'A',
                password: '1',
                app_id: 11
            }
            this.status = status
        },
        notification(text) {
            toast(text, {
                theme: "dark",
                type: "error",
                position: "top-right",
                autoClose: 3000,
            });
        }
    },
}

</script>
